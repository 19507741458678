<template>
  <el-main>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.type == 'edit' ? $t('cip.plat.sys.client.title.editHeadTitle') : (this.type == 'view' ? $t('cip.plat.sys.client.title.viewHeadTitle') : $t('cip.plat.sys.client.title.addHeadTitle'))"
                 @head-save="headSave()"
                 @head-save-cancel="headSave(true)"
                 @head-cancel="headCancel">
    </head-layout>
    <form-layout :column.sync="column" ref="formLayout" :dataForm.sync="dataForm" v-loading="formLoading"></form-layout>
  </el-main>
</template>
<script>
import {getDetail, add as saveOrUpdate} from "@/api/system/client";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {$t} from '@/lang';

export default {
  components: {FormLayout, HeadLayout},
  data() {
    return {
      imageUrl: '',
      isClose: false,
      itemData: [],
      viewFlag: true,//是否展示按钮
      formLoading: false,
      type: 'view',
      dataForm: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    headBtnOptions() {
      let res = [];
      if (['edit', 'add'].includes(this.type)) {
        res.push({
          btnOptType: 'save',
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: 'head-save',
        });
        res.push({
          btnOptType: 'saveBack',
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: 'head-save-cancel',
        });
      }
      res.push({
        btnOptType: 'cancel',
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: 'head-cancel',
      });
      return res;
    },
    column() {
      return [
        {
          label: $t("cip.plat.sys.client.field.clientId"),
          labelWidth: 160,
          prop: 'clientId',
          search: true,
          maxlength: 40,
          showWordLimit: true,
          readonly: this.viewFlag,
          rules: [{
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.clientId"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.client.field.clientSecret"),
          labelWidth: 160,
          prop: "clientSecret",
          search: true,
          maxlength: 200,
          showWordLimit: true,
          readonly: this.viewFlag,
          rules: [{
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.clientSecret"),
            trigger: "blur"
          }]
        },

        {
          label: $t("cip.plat.sys.client.field.authScope"),
          labelWidth: 160,
          prop: "scope",
          maxlength: 200,
          showWordLimit: true,
          readonly: this.viewFlag,
          value: "all",
          rules: [{
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.authScope"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.client.field.accessTokenValidity"),
          labelWidth: 160,
          prop: "accessTokenValidity",
          type: "number",
          value: 3600,
          minRows: 0,
          maxRows: 1000000000,
          disabled: this.viewFlag,
          rules: [{
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.accessTokenValidity"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.client.field.refreshTokenValidity"),
          labelWidth: 160,
          prop: "refreshTokenValidity",
          type: "number",
          disabled: this.viewFlag,
          value: 604800,
          minRows: 0,
          maxRows: 1000000000,
          hide: true,
          rules: [{
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.refreshTokenValidity"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.client.field.webServerRedirectUri"),
          labelWidth: 160,
          prop: "webServerRedirectUri",
          hide: true,
          maxlength: 200,
          showWordLimit: true,
          readonly: this.viewFlag,
          rules: [{
            required: true,
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.webServerRedirectUri"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.client.field.resourceIds"),
          labelWidth: 160,
          prop: "resourceIds",
          hide: true,
          maxlength: 200,
          showWordLimit: true,
          readonly: this.viewFlag,
          rules: [{
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.resourceIds"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.client.field.authorities"),
          labelWidth: 160,
          prop: "authorities",
          hide: true,
          maxlength: 200,
          showWordLimit: true,
          readonly: this.viewFlag,
          rules: [{
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.authorities"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.client.field.autoapprove"),
          labelWidth: 160,
          prop: "autoapprove",
          hide: true,
          maxlength: 200,
          showWordLimit: true,
          readonly: this.viewFlag,
          rules: [{
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.autoapprove"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.client.field.authType"),
          labelWidth: 160,
          prop: "authorizedGrantTypes",
          type: "checkbox",
          disabled: this.viewFlag,
          value: "refresh_token,password,authorization_code",
          dicData: [{
            label: "refresh_token",
            value: "refresh_token"
          },
            {
              label: "password",
              value: "password"
            },
            {
              label: "authorization_code",
              value: "authorization_code"
            },
            {
              label: "captcha",
              value: "captcha"
            },
            {
              label: "social",
              value: "social"
            },
            {
              label: "sinoma_code",
              value: "sinoma_code"
            }
          ],
          rules: [{
            required: true,
            message: $t("cip.cmn.rule.selectWarning") + $t("cip.plat.sys.client.field.authType"),
            trigger: "blur"
          }],
          row: true,
          span: 24,
        },
        {
          label: $t("cip.plat.sys.client.field.additionalInformation"),
          labelWidth: 160,
          hide: true,
          prop: "additionalInformation",
          span: 16,
          maxlength: 4000,
          showWordLimit: true,
          row: true,
          type: 'textarea',
          readonly: this.viewFlag,
          rules: [{
            message: $t("cip.cmn.rule.inputWarning") + $t("cip.plat.sys.client.field.additionalInformation"),
            trigger: "blur"
          }]
        }
      ];
    },

  },
  methods: {
    //保存
    headSave(clo) {
      this.dataForm.clientId = this.dataForm.clientId.trim()
      this.dataForm.clientSecret = this.dataForm.clientSecret.trim()
      this.dataForm.scope = this.dataForm.scope.trim()
      this.dataForm.webServerRedirectUri = this.dataForm.webServerRedirectUri.trim()
      this.$refs.formLayout.getForm().validate(valid => {
        if (valid) {
          this.$loading()
          saveOrUpdate(this.dataForm).then(res => {
            let {
              msg,
              data,
            } = res.data;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            ;
            this.dataForm = data;
            this.type = 'edit';
            if (clo) {
              this.close()
            }
          }).finally(() => {
            this.$refs.formLayout.$refs.form.allDisabled = false;
            this.$loading().close()
          })
        }
      })
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    formData(id) {
      //加载主表信息
      getDetail(id).then(res => {
        this.dataForm = res.data.data;
      })
    },
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type == 'add') {
      this.viewFlag = false;
    } else if (this.type == 'edit') {
      this.viewFlag = false;
      this.formData(this.$route.query.id);
    } else if (this.type == 'view') {
      this.viewFlag = true;
      this.formData(this.$route.query.id);
    }
  },
};
</script>

<style scoped>

</style>
